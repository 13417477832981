export class Indicator {
    id: string;
    name: string;
    interval: IntervalType
}

export enum IntervalType {
    daily,
    weekly,
    monthly,
    yearly
}