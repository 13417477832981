<div class="p-4">
    <div class="container-md vert-align-container">
        <div class="item col_100 cells_3_6 catlen12 ng-scope" style="width: 100%;">
            <div class="cell__wrap according_to_plan">
                <div class="cell__content">
                    <div class="cell__content_middle">
                        <img src="https://icons.iconarchive.com/icons/oxygen-icons.org/oxygen/128/Status-weather-clouds-icon.png" style="height: 65px;" alt="">                
                        <h3 style="padding-top: 0px; margin-bottom: 0px;" >Weercijfer</h3>
                        <h2 style="font-size: 0.6rem; margin-bottom: 0px;">Datum: {{ weatherScoreDate }}</h2>
                        <h2 style="font-size: 1.3rem;">{{ weatherScore }}</h2>
                    </div>
                </div>
            </div>
            <div class="cell__wrap {{ cnpsCustomerIndicator }}">
                <div class="cell__content">
                   <div class="cell__content_middle">
                      <img src="../assets/images/indicators/klanttevredenheid.png" alt="">                
                      <h3>Klanttevredenheid</h3>
                      <h2 style="font-size: 1.3rem;">{{ cnpsCustomer }}</h2>
                   </div>
                </div>
            </div>
            <div class="cell__wrap {{ cnpsEmployeeIndicator }}">
                <div class="cell__content">
                    <div class="cell__content_middle">
                        <img src="../assets/images/indicators/medewerkers_tevredenheid.png" alt="">                
                        <h3>Medewerkers tevredenheid</h3>
                        <h2 style="font-size: 1.3rem;">{{ cnpsEmployee }}</h2>
                    </div>
                </div>
            </div>
            <div class="cell__wrap {{ cnpsWorkPrivateIndicator }}">
               <div class="cell__content">
                  <div class="cell__content_middle">
                     <img src="../assets/images/indicators/balans_werk_en_prive.png" alt="">                
                     <h3>Balans werk en privé</h3>
                     <h2 style="font-size: 1.3rem;">{{ cnpsWorkPrivate }}</h2>
                  </div>
               </div>
            </div>
            <div class="cell__wrap {{ revenueIndicator }}">
               <div class="cell__content">
                  <div class="cell__content_middle">
                     <img src="../assets/images/indicators/omzet.png" alt="">                
                     <h3>Omzet</h3>
                     <h2 style="font-size: 1.3rem;">{{ revenue }}</h2>
                  </div>
               </div>
            </div>
         </div>
    </div>
</div>