import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountService, IndicatorService } from '@app/_services';
import { environment } from '@environments/environment';

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent {
    account = this.accountService.accountValue;
    weatherScore = '-';
    weatherScoreDate = '';
    cnpsCustomer = '-';
    cnpsEmployee = '-';
    cnpsWorkPrivate = '-';
    revenue = '-';
    cnpsCustomerIndicator = 'according_to_plan';
    cnpsEmployeeIndicator = 'according_to_plan';
    cnpsWorkPrivateIndicator = 'according_to_plan';
    revenueIndicator = 'according_to_plan';
    interval;

    ngOnDestroy() {
        if (this.interval) {
          clearInterval(this.interval);
        }
      }

    constructor(private accountService: AccountService, private http: HttpClient, private indicatorService: IndicatorService) { 
        this.http.get<any>(`${environment.apiUrl}/indicator/weatherscore`,{responseType: 'json'}).subscribe(data => {
            this.weatherScore = data.slice(-1)[0][1];
            const date = data.slice(-1)[0][0];
            this.weatherScoreDate = `${date.substring(6,8)}-${date.substring(4,6)}-${date.substring(0,4)}`;
            console.log(data)
        });
        
        this.updateNps();
        this.updateRevenue();
        this.interval = setInterval(() => {
            this.updateNps();
        }, 10000);
    }

    updateRevenue(){
        this.http.get<any>(`${environment.apiUrl}/integrations/exactonlinerevenue`,{responseType: 'json'}).subscribe(data => {
            console.log(data);
            var revenue = parseInt(data.slice(-1)[0]['Amount']);
            if(revenue < 0){
                this.revenueIndicator = 'focus'
            }else{
                this.revenueIndicator = 'good'
            }
            this.revenue = String(revenue);
        });
    }

    updateNps(){
        this.http.get<any>(`${environment.apiUrl}/integrations/cnps`,{responseType: 'json'}).subscribe(data => {
            console.log(data);
            //Customer
            var pos = 0;
            var neg = 0;
            var total = data.length;

            if(total>0){
                data.forEach(element => {
                    if (element.score<7){
                        neg++;
                    }else if(element.score>8){
                        pos++;
                    }
                });
                var negScore = neg * 50 / total;
                var posScore = pos * 50 / total;
                var score = Math.round(50 + (posScore - negScore));
                if (score <= 25){
                    this.cnpsCustomerIndicator = 'problem';
                }else if (score <= 45 ){
                    this.cnpsCustomerIndicator = 'focus';
                }else if (score <= 55 ){
                    this.cnpsCustomerIndicator = 'according_to_plan';
                }else if (score <= 75 ){
                    this.cnpsCustomerIndicator = 'good';
                }else{
                    this.cnpsCustomerIndicator = 'excellent';
                }
                if(score.toString() != this.cnpsCustomer){
                    this.cnpsCustomer = score.toString();
                    console.log(data);
                }
            }
        });

        this.http.get<any>(`${environment.apiUrl}/integrations/enps`,{responseType: 'json'}).subscribe(data => {
            console.log(data);
            //Employee
            var pos = 0;
            var neg = 0;
            var total = data.enps.length;

            if(total>0){
                data.enps.forEach(element => {
                    if (element.score<7){
                        neg++;
                    }else if(element.score>8){
                        pos++;
                    }
                });
                var negScore = neg * 50 / total;
                var posScore = pos * 50 / total;
                var score = Math.round(50 + (posScore - negScore));
                if (score <= 25){
                    this.cnpsEmployeeIndicator = 'problem';
                }else if (score <= 45 ){
                    this.cnpsEmployeeIndicator = 'focus';
                }else if (score <= 55 ){
                    this.cnpsEmployeeIndicator = 'according_to_plan';
                }else if (score <= 75 ){
                    this.cnpsEmployeeIndicator = 'good';
                }else{
                    this.cnpsEmployeeIndicator = 'excellent';
                }
                if(score.toString() != this.cnpsEmployee){
                    this.cnpsEmployee = score.toString();
                    console.log(data.enps);
                }
            }

            //Work Private balance
            var pos = 0;
            var neg = 0;
            var total = data.balansWerkPrive.length;

            if(total>0){
                data.balansWerkPrive.forEach(element => {
                    if (element.score<7){
                        neg++;
                    }else if(element.score>8){
                        pos++;
                    }
                });
                var negScore = neg * 50 / total;
                var posScore = pos * 50 / total;
                var score = Math.round(50 + (posScore - negScore));
                if (score <= 25){
                    this.cnpsWorkPrivateIndicator = 'problem';
                }else if (score <= 45 ){
                    this.cnpsWorkPrivateIndicator = 'focus';
                }else if (score <= 55 ){
                    this.cnpsWorkPrivateIndicator = 'according_to_plan';
                }else if (score <= 75 ){
                    this.cnpsWorkPrivateIndicator = 'good';
                }else{
                    this.cnpsWorkPrivateIndicator = 'excellent';
                }
                if(score.toString() != this.cnpsWorkPrivate){
                    this.cnpsWorkPrivate = score.toString();
                    console.log(data.balansWerkPrive);
                }
            }
        });
    }
}