import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Indicator, UserIndicator, Integration } from '@app/_models';

const baseUrl = `${environment.apiUrl}/indicators`;

@Injectable({ providedIn: 'root' })
export class IndicatorService {
    private indicatorSubject: BehaviorSubject<Indicator>;
    private userIndicatorSubject: BehaviorSubject<UserIndicator>;
    private integrationSubject: BehaviorSubject<Integration>;
    public indicator: Observable<Indicator>;
    public userIndicator: Observable<UserIndicator>;
    public integration: Observable<Integration>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.indicatorSubject = new BehaviorSubject<Indicator>(null);
        this.userIndicatorSubject = new BehaviorSubject<UserIndicator>(null);
        this.integrationSubject = new BehaviorSubject<Integration>(null);
        this.indicator = this.indicatorSubject.asObservable();
        this.userIndicator = this.userIndicatorSubject.asObservable();
        this.integration = this.integrationSubject.asObservable();
    }

    public get indicatorValue(): Indicator {
        return this.indicatorSubject.value;
    }

    getIndicators() {
        return this.http.get<Indicator[]>(baseUrl);
    }

    getIntegrations() {
        return this.http.get<Integration[]>(`${environment.apiUrl}/integrations`);
    }

    getUserIndicators(id: string){
        return this.http.get<UserIndicator[]>(`${baseUrl}/${id}`);
    }

    getById(id: string) {
        return this.http.get<Indicator>(`${baseUrl}/${id}`);
    }

    createExactOnlineIntegration(code: string){
        return this.http.post(`${environment.apiUrl}/integrations/exactonline`, { code });
    }

    getIntegrationsPageData() {
        return this.http.get<any[]>(`${environment.apiUrl}/integrations/integrationspagedata`);
    }
    
}