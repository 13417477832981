<div class="app-container" [ngClass]="{ 'bg-white': account }">
    <!-- main nav -->
    <nav class="navbar navbar-expand navbar-light bg-light" *ngIf="account">
        <navbar-brand>
            <img src="../assets/images/logo.png" alt="" height="50" width="50">
            <a class="navbar-brand" href="#">SCOREBOARD</a>
        </navbar-brand>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <div class="navbar-nav">
                <a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="nav-item nav-link">Dashboard</a>
                <a routerLink="/settings" routerLinkActive="active"  class="nav-item nav-link">Instellingen</a>
                <a routerLink="/profile" routerLinkActive="active" class="nav-item nav-link">Account</a>
                <a *ngIf="account.role === Role.Admin" routerLink="/admin" routerLinkActive="active" class="nav-item nav-link">Admin</a>
                <a (click)="logout()" class="nav-item nav-link">Uitloggen</a>
            </div>
        </div>
    </nav>

    <!-- subnav router outlet -->
    <router-outlet name="subnav"></router-outlet>

    <!-- global alert -->
    <alert></alert>

    <!-- main router outlet -->
    <router-outlet></router-outlet>
</div>
